<template>
  <modal-form
    v-slot="{ properties, errors, entity: originalEntity }"
    type="editCustomerGroup"
    :options="{
      properties: {
        name: '',
        number: '',
        manual: true,
        description: '',
        customers: [],
        promotions: [],
        enabledStores: []
      },
      postUri: '/customer_groups',
      label: $t('customerGroup.label'),
    }"
    :save-func="save"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input v-model="properties.number" :label="$t('customerGroup.number.label')" :error="errors.number" required />
    <fancy-checkbox v-model="properties.manual" :label="$t('customerGroup.manual.label')" :error="errors.number" required />
    <fancy-input v-model="properties.description" :label="$t('general.field.description')" :error="errors.description" textarea />

    <multiselect
      v-model="properties.promotions"
      :options="async (q) => loadPromotionOptions(q, properties.promotions)"
      :loading="loadingPromotionOptions"
      :filter-results="false"
      :form-label="$t('promotion.label', 2)"
      :error="errors.promotions"
      mode="tags"
      searchable
    />

    <multiselect
      v-model="properties.enabledStores"
      :options="async (q) => loadStoreOptions(q, properties.enabledStores)"
      :loading="loadingStoreOptions"
      :filter-results="false"
      :form-label="$t('store.label', 2)"
      :error="errors.enabledStores"
      :placeholder="$t('store.all')"
      mode="tags"
      searchable
    />

    <template v-if="!originalEntity['@id']">
      <hr>
      <fancy-checkbox v-model="batchCreateCustomers" :label="$t('customerGroup.batchCreate')" />

      <customer-batch-form v-if="batchCreateCustomers" v-model="batchData" />
    </template>
  </modal-form>
</template>

<script>
import { ref } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import { useStore } from 'vuex';
import axios from '@/services/axios';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import CustomerBatchForm from '@/components/entities/customer/CustomerBatchForm.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditCustomerGroupModal',
  components: {
    FancyCheckbox,
    CustomerBatchForm,
    Multiselect,
    FancyInput,
    ModalForm,
  },
  props: {
    entity: { type: Object, default: () => ({}) },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();
    const batchCreateCustomers = ref(false);
    const batchData = ref({
      rangeStart: null,
      amount: 1,
      balance: { currency: 'CHF', amount: 0 },
      identifiers: [10],
    });

    const save = async (newValue, originalValue, errors, originalSaveFunc) => {
      if (batchCreateCustomers.value === true) {
        try {
          await axios.post('/customers/batch_create', {
            customerGroup: { ...newValue },
            ...batchData.value,
          });
          emit('update');
          store.dispatch('modals/closeModal', 'editCustomerGroup');
          notify('Kundengruppe erstellt');
        } catch (err) {
          if (err.response?.status === 422 && err.response?.data?.violations != null) {
            err.response.data.violations.forEach((v) => {
              if (v.propertyPath && v.message) {
                errors[v.propertyPath] = v.message;
              }
            });
            notify({
              title: 'Ungültige Daten',
              type: 'warn',
            });
          } else {
            console.error(err, err.message, err.response);
            notify({
              title: 'Fehler beim Speichern',
              text: err?.response?.data?.['hydra:description'] ?? null,
              type: 'error',
            });
          }
        }
      } else {
        await originalSaveFunc(newValue, originalValue);
      }
    };

    const {
      loading: loadingPromotionOptions,
      loadOptions: loadPromotionOptions,
    } = useLoadOptions('/promotions', 'name');

    const {
      loading: loadingStoreOptions,
      loadOptions: loadStoreOptions,
    } = useLoadOptions('/stores', 'name');

    return {
      batchCreateCustomers,
      save,
      loadPromotionOptions,
      loadingPromotionOptions,
      loadStoreOptions,
      loadingStoreOptions,
      batchData,
    };
  },
};
</script>
